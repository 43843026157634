<script>
import { users } from "@/config/api/users";
import router from "@/router/index";

export default {
  data() {
    return {
      formValues: {},
      resetting: false,
    };
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  methods: {
    forgotPassword() {
      const api = users.forgotPassword;
      api.data = {
        email: this.formValues.email,
      };

      this.resetting = true;
      this.generateAPI(api)
        .then( () => {
          
          this.$bvToast.toast(
            "Reset link sent to your email successfully. Please check your email !",
            {
              title: "Email sent",
              variant: "success",
              solid: true,
            }
          );
           setTimeout(() => {
            router.push({ name: "login" });
          }, 3000);
        })
        .catch((err) => {
          this.$bvToast.toast(`Error!, ${err.response.data.message}`, {
            title: "Forgot password",
            variant: "danger",
            solid: true,
          });
          this.resetting = false;
        });
    },
  },
};
</script>

<template>
  <div>
   
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-12">
            <div
              class="authentication-page-content p-4 d-flex align-items-center min-vh-100"
            >
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-4">
                    <div style="max-width:380px;margin:auto">
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img
                              class="mowj-login-logo"
                              src="@/assets/mawj/logo.svg"
                              height="80"
                              width="200"
                              alt="logo"
                              srcset=""
                            />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">Reset Password</h4>
                        <p class="text-muted">Reset your password to Mawj</p>
                      </div>

                      <div class="p-2 mt-2">
                        <b-alert
                          v-model="isResetError"
                          class="mb-4"
                          variant="danger"
                          dismissible
                          >{{ error }}</b-alert
                        >

                        <FormulateForm
                          v-model="formValues"
                          @submit="forgotPassword"
                        >
                          <FormulateInput
                            name="email"
                            type="email"
                            label="Email"
                            placeholder="Enter your email"
                            validation="required"
                          />

                          <div class="mt-4 text-center">
                            <FormulateInput
                              class="font-weight-medium text-primary"
                              type="submit"
                              :label="resetting ? 'Resetting...' : 'Reset'"
                            />
                          </div>
                        </FormulateForm>

                        
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          <router-link
                            tag="a"
                            to="/login"
                            class="font-weight-medium text-primary"
                            >Log in</router-link
                          >
                        </p>
                        <p>© 2022 Mawj.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>